.card-hover {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 260px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 8px 8px #96674060;
  }
}

.card-hover-skill {
  border: 1px solid transparent;
  &:hover {
    box-shadow: 0px 0px 8px 8px #f995004d;
    border: 1px solid #da8b14;
  }
}

.card-background {
  background-size: 100% 100% !important;
}

.slick-list {
  max-width: calc(100vw - 80px);
  margin: auto;
}
.slick-next::before,
.slick-prev::before {
  content: '';
}

.slick-dots {
  bottom: 12px;

  li button::before,
  li.slick-active button::before {
    color: #fff;
    font-size: 12px;
  }
}

.swiper-wrapper {
  max-width: calc(100vw - 80px);
  margin: auto;
}
